<template>
  <div class="section--maps">
    <div class="map--wrapper">
      <div id="map" class="map--container"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lat: {
      type: String,
    },
    lng: {
      type: String,
    },
  },
  data: () => ({
    map: '',
  }),
  mounted() {
    this.$nextTick(() => {
      if (this.lat && this.lng) {
        this.initMap();
      }
    });
  },
  methods: {
    initMap() {
      console.log('INIT MAP OFFICIAL PARTNER AREA: ', `${this.lat}, ${this.lng}`);
      let self = this;
      let icon = {
        url: '/img/map_pin.png', // url
        // eslint-disable-next-line no-undef
        scaledSize: new google.maps.Size(50, 50), // scaled size
        // eslint-disable-next-line no-undef
        origin: new google.maps.Point(0, 0), // origin
        // eslint-disable-next-line no-undef
        anchor: new google.maps.Point(25, 50), // anchor
      };
      const element = document.getElementById('map');
      const options = {
        center: {
          lat: parseFloat(self.lat),
          lng: parseFloat(self.lng),
        },
        mapType: 'hybrid',
        zoom: 16,
        maxZoom: 18,
        zoomControl: true,
        zoomControlOptions: {
          // eslint-disable-next-line no-undef
          position: google.maps.ControlPosition.LEFT_TOP,
        },
        streetViewControl: true,
      };
      // eslint-disable-next-line no-undef
      self.map = new google.maps.Map(element, options);

      // eslint-disable-next-line
      let marker = new google.maps.Marker({
        // eslint-disable-next-line no-undef
        position: new google.maps.LatLng(parseFloat(self.lat), parseFloat(self.lng)),
        icon: icon,
        map: self.map,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.map--container {
  background-color: #ececec;
  height: 360px;
}
</style>
